<template>
  <span>
    {{ displayValue }}
  </span>
</template>

<script>
import moment from "moment";
import { defineComponent } from "vue";
import { dateTimeFormat } from "@/config/env";

export default defineComponent({
  props: {
    value: String,
    format: {
      type: String,
      default: dateTimeFormat,
    },
  },

  computed: {
    displayValue() {
      return moment(this.value).format(this.format);
    },
  },
});
</script>
